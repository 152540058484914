import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});


function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    setFetchAttempted(true); // Set to true as fetch begins
  
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
  
        if (decodedCity === "unknown") {
          // If city is "unknown", fetch from the external API
          fetch('https://freeipapi.com/api/json/')
            .then(response => response.json())
            .then(externalData => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData); // Log the external API data
            })
            .catch(error => {
              console.error('Fetch error on external API:', error);
            });
        } else {
          // Use the local API data
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase())); // Assumes regionNames is defined and imported
          setCity(decodedCity);
          console.log(data); // Log the local API data
        }
      })
      .catch(error => {
        console.error('Fetch error on local API:', error);
        // Optionally handle the error by fetching from external API or other means
      });
  
  }, []);

  useEffect(() => {
    // Store the original body style
    const originalStyle = document.body.style.cssText;
  
    // Set the new background style
    document.body.style.backgroundImage = "url('https://i.ibb.co/94dykKW/bg.gif')";
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.minHeight = '100vh';
  
    // Reset to the original style on component unmount
    return () => {
      document.body.style.cssText = originalStyle;
    };
  }, []);

  const imageUrls = [
    'https://i.ibb.co/BKCCWSC/cropped-photo-2024-07-04-17-01-34.jpg',
    'https://i.ibb.co/HC6v32x/cropped-photo-2024-07-04-17-01-37.jpg',
    'https://i.ibb.co/kSsVym8/cropped-photo-2024-07-04-17-01-39.jpg',
    'https://i.ibb.co/Dw8hVXt/cropped-photo-2024-07-04-17-01-40.jpg',
    'https://i.ibb.co/y6FJrSn/cropped-photo-2024-07-04-17-01-42.jpg',
    'https://i.ibb.co/tDZ3dLk/cropped-photo-2024-07-04-17-01-43.jpg',
    'https://i.ibb.co/svz4Gzq/cropped-photo-2024-07-04-17-01-44.jpg'
  ];

  return (
    <div className="container">
      <img 
        src="https://assets.cdn.filesafe.space/CJkDD96k2jOfuywnYhgP/media/65a51f792aaf1b036608af13.png" 
        alt="Header Image"
        className="header-image"
      />
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'3'}
        coverflowEffect={{
        rotate: 15,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
       }}
      autoplay={{ 
        delay: 2000,
        disableOnInteraction: false,
      }}
      modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
      className="swiper-container"
    >
      {imageUrls.map(url => (
        <SwiperSlide key={url} className="swiper-slide">
          <img src={url} alt="slide_image"/>
        </SwiperSlide>
      ))}
        <div className="slider-controler">
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>

          <div className='textContainer' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <p style={{ fontSize: '31px', margin: '0 0 10px 0' }}> 
            <strong>🎁80% OFF SALE🎁 Nelly🧸</strong>
        </p>
        <div className="detailsText">
            <strong>📍 {city}, {country} {country && <img 
                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                alt={`${country} Flag`}
                style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
            />} ❤️18 years ♉ Taurus<br/></strong>
            🟢 Online Now<br/><br/>

            Get exclusive access to my content and interact with me directly. <br/><br/>

            🍑 💦 Do you live near<strong> {city}</strong>?📍 DM me about making content 😉 🍑 🔥
        </div>
    </div>



      <a href="https://onlyfans.com/ornedolce/c1" id="customButton" >
        Send me a message
        <img src="https://www.edigitalagency.com.au/wp-content/uploads/OnlyFans-logo-symbol-icon-png-blue-background.png" alt="Logo" />
      </a>

      <p className="exclusiveContent">
       <strong>Get access to my private account now!<br/>
       Only $3.00/m for a limited time!</strong>
      </p>
      <Analytics/>
    </div>
  );
}

export default App;
